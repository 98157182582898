// Icon Mixins Begin Here
@mixin common-icon-styles($icon-source) {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px;
    background-image: url("#{$icon-source}") !important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@mixin popup-main-icon-styles($icon-source) {
  width: 115px;
  height: 96px;
  // background-size: 115px;
  background-image: url("#{$icon-source}") !important;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto 32px auto;
}
// Icon Mixins End Here

// Context Menu Mixins Begin Here
@mixin context-menu-root-styles() {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 12em;
  max-width: 16em;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $card-background-color;
  border: 1px solid $input-border-color;
  border-radius: 8px;
  box-shadow: $common-box-shadow;
  color: $default-text-color;
}

@mixin context-menu-item-styles($icon-source) {
  font-size: $default-text-font-size;
  color: $base-color;
  padding: 12px 12px 12px 42px;

  &:hover {
    background-color: $c-secondary-background-color !important;
  }

  &::before {
    content: "";
    width: 1.5em;
    height: 1.5em;
    margin-left: 12px;
    background-image: url("#{$icon-source}") !important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
// Context Menu Mixins End Here

// Error  Mixins End Here
@mixin form-error() {
  color: $c-error-color;
  font-size: $default-text-font-size;
  line-height: 20px;
  letter-spacing: -0.1px;
  margin-top: 8px;
  display: block;
}
// Error Mixins End Here

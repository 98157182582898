@use "../scss/helpers/" as helpers;
@import "variables.scss";

@mixin modal-h1-styles {
  text-align: center;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: -0.34px;
  font-weight: bold;
  margin-bottom: 0;
}

.capassa-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 80%;
  background-color: rgba($c-box-shadow, 0.5);

  .capassa-modal-content {
    background-color: $default-background-color;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid $default-background-color;
    width: 80%;
    border-radius: 16px;
  }
}

.container {
  .capassa-modal {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .capassa-modal-content {
      padding: 32px;
      width: 600px;
      border: none;
      min-height: unset;

      .capassa-modal-inner {
        .h2 {
          font-size: 24px;
          line-height: 34px;
          letter-spacing: -0.3px;
          font-weight: 700;
          margin-top: 32px;
          margin-bottom: 32px;
          text-align: center;
        }
      }

      .spacer {
        display: inherit;
      }

      .modal-sub-header {
        display: none;
      }

      .manage-integration-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding-bottom: 32px;

        .h1 {
          font-size: 24px;
          line-height: 34px;
          letter-spacing: -0.3px;
          margin: 0;
          padding: 0;
          font-weight: 700;
        }

        .link {
          &.link-close {
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url(../images/icon_app_menu_close-gray.svg);
          }
        }
      }

      .invalid-feedback {
        &.error-m {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.1px;
          color: $c-error-color;
          margin: 8px 0 8px 0;
          padding: 0;
        }
      }

      .invalid-feedback-2 {
        width: 100%;
        padding: 24px 30px 24px 56px;
        background-color: $c-error-message-bg-color;
        border-radius: 8px;
        display: block !important;
        margin-bottom: 20px;
        margin-top: 32px;
        color: $base-color;
        border-left: 6px solid $c-error-color;
        background-image: url(../images/icon-error-face.svg);
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: 16px center;

        &.error-m {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.1px;
        }
      }

      .app-message {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.1px;
        padding: 34px 24px 34px 56px;
        background-color: $c-error-message-bg-color;
        border-radius: 8px;
        display: block !important;
        margin-bottom: 0;
        margin-top: 32px;
        color: $base-color;
        border-left: 6px solid $c-error-color;
        background-image: url(../images/icon-error-face.svg);
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: 16px center;

        &.success-message {
          background-color: $c-success-message-bg-color;
          border-left: 6px solid $c-primary-color;
          background-image: url(../images/icon-sucs-face.svg);
        }

        &.error-message {
          background-color: $c-error-message-bg-color;
          border-left: 6px solid $c-error-color;
          background-image: url(../images/icon-error-face.svg);
        }
      }

      .btn {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.1px;
        border-radius: 8px;
        height: 48px;
        width: 100%;
        text-align: center;
        outline: none !important;
        padding: 8px 0;

        &.btn-primary {
          background-color: $c-primary-color;
          border-color: $c-primary-color;
          color: $default-background-color;
          margin-top: 32px;

          &:hover {
            background-color: $c-primary-color-hover;
            border-color: $c-primary-color-hover;
          }

          &:active {
            background-color: $c-primary-color-hover !important;
            outline: none;
            border: none;
          }

          &:focus {
            outline: 0 !important;
          }
        }

        &.btn-secondary {
          background-color: $default-background-color;
          border-color: $c-primary-color;
          color: $c-primary-color;
          margin-top: 16px !important;

          &:hover {
            background-color: $c-secondary-color-hover;
          }

          &:active {
            background-color: $c-secondary-color-hover !important;
            outline: none;
            border: 1px solid $c-primary-color;
          }

          &:focus {
            outline: 0 !important;
          }
        }
      }
    }

    .capassa-modal-footer {
      display: none;
    }
  }

  &.company-onboarding,
  &.user-signup-flow,
  &.user-invitation-flow {
    .capassa-modal {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;

      .capassa-modal-content {
        width: 664px;
        max-height: calc(100% - 48px);
        padding: 48px 64px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .capassa-modal-inner {
          height: calc(100% - 40px);
          overflow-y: auto;
          width: 100%;

          .h2 {
            font-size: 32px;
            line-height: 42px;
            letter-spacing: -0.34px;
          }

          .app-message {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.18px;

            &.success-message {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -0.18px;
            }
          }
        }

        .spacer {
          display: none;
        }

        .modal-sub-header {
          display: inherit;
        }

        .manage-integration-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-wrap: nowrap;
          padding-bottom: 16px;

          .h1 {
            text-align: center;
            font-size: 32px;
            line-height: 42px;
            letter-spacing: -0.34px;
            font-weight: bold;
            margin-bottom: 0;
            width: 100%;
          }
        }

        .h1 {
          text-align: center;
          font-size: 32px;
          line-height: 42px;
          letter-spacing: -0.34px;
          font-weight: bold;
          margin-bottom: 0;
        }
        .p {
          text-align: center;
          font-size: 24px;
          line-height: 34px;
          letter-spacing: -0.3px;
          color: $c-sub-text-color;
          margin: 16px 0 0 0;
          padding: 0;
        }
        .modal-sub-header {
          text-align: center;
          font-size: 24px;
          line-height: 34px;
          letter-spacing: -0.3px;
          color: $c-sub-text-color;
          margin: 0 0 32px 0;
          padding: 0;
        }

        .btn {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.18px;

          &.btn-primary {
            background-color: $c-primary-color;
            height: 48px;
            width: 100%;
            text-align: center;
            margin-top: 32px;
            padding: 0;
            border: 0;
            border-radius: 8px;

            &:disabled {
              opacity: 0.5;

              &:hover {
                background-color: $c-primary-color;
              }
            }

            &:hover {
              background-color: $c-primary-color-hover;
            }
          }

          &.btn-secondary {
            background-color: transparent;
            color: $c-primary-color;
            height: 48px;
            width: 100%;
            text-align: center;
            margin-top: 16px;
            padding: 0;
            border: 1px solid $c-primary-color;
            border-radius: 8px;

            &:disabled {
              opacity: 0.5;
            }

            &:hover {
              background-color: $c-secondary-color-hover;
            }
          }
        }

        a {
          color: $c-primary-color;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        label {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.1px;
        }

        .form-control {
          border: 1px solid $base-color;
          border-radius: 8px;
          height: 48px;
          background-color: $default-background-color;
          padding: 10px 16px;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.18px;

          &:focus {
            background-color: $default-background-color;
            border-color: $base-color;
            outline: 0;
            box-shadow: none;
          }
        }

        .error-m {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.18px;
        }
      }

      .integration-flow {
        min-height: 400px;
      }
    }
  }

  &.user-signup-flow {
    .capassa-modal-footer {
      display: block;
    }
  }
}

@media (max-width: 575.98px) {
  .container {
    .capassa-modal {
      .capassa-modal-content {
        min-height: unset;
        width: calc(100vw - 32px);
        padding: 24px;

        .capassa-modal-inner {
          .h2 {
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.27px;
          }
        }

        .manage-integration-header {
          padding-bottom: 24px;

          .h1 {
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.21px;
            font-weight: 700;
          }
        }

        .invalid-feedback {
          &.error-m {
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.05px;
          }
        }

        .invalid-feedback-2 {
          padding: 24px 16px 24px 62px;
          border-left: 3px solid $c-error-color;
          background-position: 16px center;

          &.error-m {
            font-size: 15px;
            line-height: 21px;
            letter-spacing: -0.14px;
          }
        }

        .app-message {
          padding: 24px 16px 24px 62px;
          border-left: 3px solid $c-error-color;
          background-position: 16px center;
          font-size: 15px;
          line-height: 21px;
          letter-spacing: -0.14px;

          &.success-message {
            background-color: $c-success-message-bg-color;
            border-left: 3px solid $c-primary-color;
          }

          &.error-message {
            border-left: 3px solid $c-error-color;
          }
        }

        .btn {
          font-size: 15px;
          line-height: 21px;
          letter-spacing: -0.14px;
          height: 40px;

          &.btn-primary {
            height: 40px;
          }

          &.btn-secondary {
            height: 40px;
          }
        }
      }
    }

    &.company-onboarding,
    &.user-signup-flow,
    &.user-invitation-flow {
      .capassa-modal {
        .capassa-modal-content {
          width: calc(100% - 32px);
          padding: 24px;
          max-height: calc(100% - 32px);

          .capassa-modal-inner {
            .h2 {
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -0.27px;
            }

            .app-message {
              font-size: 15px;
              line-height: 21px;
              letter-spacing: -0.14px;

              &.success-message {
                font-size: 15px;
                line-height: 21px;
                letter-spacing: -0.14px;
              }
            }
          }

          .h1 {
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.27px;
          }

          .p {
            text-align: center;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: 0px;
          }

          .modal-sub-header {
            text-align: center;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: 0px;
          }

          .manage-integration-header {
            .h1 {
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -0.27px;
            }
          }

          .btn {
            font-size: 15px;
            line-height: 21px;
            letter-spacing: -0.14px;
          }

          label {
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.05px;
          }

          .form-control {
            font-size: 15px;
            line-height: 21px;
            letter-spacing: -0.14px;
          }

          .error-m {
            font-size: 15px;
            line-height: 21px;
            letter-spacing: -0.14px;
          }
        }

        ::ng-deep .dot-check-container {
          .progress-section {
            .dot {
              width: 10px !important;
              height: 10px !important;
              margin-right: 16px !important;
            }
          }
        }
      }
    }

    &.user-signup-flow {
      .capassa-modal-footer {
        display: block;
      }

      .accounting-success-modal {
        .h2 {
          font-size: 28px;
          line-height: 42px;
          letter-spacing: -0.34px;
        }

        .app-message {
          &.success-message {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.18px;
          }
        }
      }
    }
  }
}

.modal {
  display: flex !important;
  align-items: center !important;

  .modal-dialog {
    flex-grow: 1;
    max-width: 600px;

    .modal-content {
      border-radius: $box-border-radius;
      border: none;
      width: 600px;
      padding: 32px;

      .h2 {
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.3px;
        font-weight: 700;
        margin: 32px 0;
        text-align: center;
      }

      .app-message {
        margin-bottom: 32px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.1px;
        padding-right: 24px;
        padding-top: 24px;
        padding-bottom: 24px;
      }

      .actions {
        .btn {
          height: 48px;
          width: 100%;

          &.btn-secondary {
            margin-top: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .modal {
    .modal-dialog {
      margin-left: 16px;
      margin-right: 16px;

      .modal-content {
        width: 100%;
        padding: 24px;

        .h2 {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.27px;
          margin-top: 32px;
          margin-bottom: 32px;
        }

        .app-message {
          margin-bottom: 32px;
          font-size: 15px;
          line-height: 21px;
          letter-spacing: -0.14px;
        }

        .actions {
          .btn {
            font-size: 15px;
            line-height: 21px;
            letter-spacing: -0.14px;
            height: 40px;
            width: 100%;

            &.btn-secondary {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}

@include helpers.breakpointMax(380px, portrait) {
  .modal .modal-dialog .modal-content {
    margin-top: 120px;
  }
}

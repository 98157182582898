@use "../helpers/" as helpers;

.radio-button-label {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 10px 10px 10px 0;
  font-size: 13px;
  cursor: pointer;
  position: relative;

  &:first-child {
    padding-left: 0;
  }
}

input[type="radio"] {
  display: none;
}

.radio-button-label::before {
  content: "";
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.radio-button-label::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.3s ease;
}

input[type="radio"]:checked + .radio-button-label::before {
  border-color: var(--secondary-theme-color);
}

input[type="radio"]:checked + .radio-button-label::after {
  background-color: var(--secondary-theme-color);
  transform: translate(-50%, -50%) scale(1);
}

$base-color: #2a4365;
$app-bg-color: #f7fafc;
$input-fields-bg-color: #f7fafc;
$secondary-color: #edf2f7;
$secondary-button-color: #ffffff;
$button-primary-text-color: #f7fafc;
$login-btn-color: #48bb78;
$button-primary-border-color: #a0aec0;
$card-background-color: #ffffff;
$default-background-color: #ffffff;
$c-secondary-background-color: #eaebee;
$box-border-radius: 1.5rem;
$popup-border-radius: 1.5rem;
$border-radius: 10px;
$input-border-radius: 4px 4px 0px 0px;
$input-border-color: #c4c4c4;
$input-border-color-2: #aeb8c5;
$error-color: #f56565;
$c-primary-color: #18c88c;
$c-primary-color-alt: #208b7a;
$c-sub-text-color: #69768f;
$c-error-color: #fa5040;
$c-pending-color: #faa00a;
$c-box-shadow: #000000;
$c-primary-color-hover: #139a6c;
$c-main-border-color: #cbcbcb;
$c-input-color: #eef0f3;
$c-secondary-color-hover: #139a6c0d;
$c-info-message-bg-color: #1881c80a;
$c-success-message-bg-color: #18c88c0a;
$c-error-message-bg-color: #fa504008;
$c-border-color-1: #ededed;
$c-border-color-2: #c7c7cc;
$c-scrollbar-track-color-1: #f7fafc;
$c-scrollbar-track-color-2: #f0f0f3;
$c-scrollbar-track-color-hover: #868686;
$c-budget-column-alt: #f5f6f7;

$c-page-scrollbar-thumb: #dbdcdd;
$c-page-scrollbar-track: #f2f3f5;

$tips-border-color-1: #ededed;
$tips-bg-color-1: #f7f8f9;
$tips-bg-color-1-hover: #2a436514;
$c-error-message-bg-color: #fa504008;
$term-condition-background-color: #f4f5f7;
$invitation-color: #ddd;
$invitation-box-shadow: #00000008;
$email-body: #f0f0f5;
$drag-hover: #f9fbfc;

$val-row-border-color: #d9d9d9;
$val-row-summary: #f7f7f7;

$level-0: #ed868b;
$level-1: #ffaaaa;
$level-2: #f5bd7c;
$level-3: #88e6c3;
$level-4: #4dc4b1;
$none-color: #cccccc;

$gross-profit-margin: #007aff;
$operating-profit-margin: #4c6dd9;
$net-profit-margin: #5856d6;
$equity-ratio: #6260f0;
$working-capital: #4c88d9;
$current-ratio: #2E372F;
$quick-ratio: #1ccae6;
$equity: #2ac39f;
$liabilities: #ff9f0a;
$assets: #34c759;
$expense: #ff5a5f;
$freeCashFlow: #3f75be;
$ebitda: #3c5bc3;
$nibd: #3a5098;
$nibdEbitda: #161dc1;
$undistributedProfitLoss: #ce7d0f;
$revenue: #18c883;
$ebitdaMargin: #8b2b98;
$ebit: #2b3098;
$ebitMargin: #001256;
$grossProfit: #2f0022;
$burnRate: #d60042;
$cashRunway: #faa00a;
$cashAndCashEquivalents: #436436;
$interestBearingDebt: #2b3098;
$debtAndEquity: #2a4365;
$noOfEmployees: #4e6151;
$nibdCash: #c06e52;
$dropdownHover: #eaecf0;
$roce: #4e6151;
$roe: #436436;
$interestCoverageRatio: #228b22;
$debtServiceRatio: #663399;
$roa: #2F6F71;

$power-office: #e7615a;
$fiken: #39368d;
$seven-office: #609cf3;
$brreg: #39368d;

$chart-footer: #f6f7f9;
$highlights-bar: #ff5a5f;

$common-box-shadow: rgba($base-color, 0.05) 0px 2px 6px 3px;

$marketplace-1: #f3f8f9;
$marketplace-2: #f0f0f5;
$marketplace-3: #534e4d;
$marketplace-4: #f0f0f599;
$marketplace-5: #69768f19;
$marketplace-6: #e5f3f0;
$marketplace-bg-1: #f7fafc00;
$marketplace-bg-2: #2a43650f;
$marketplace-bg-3: #0c64461a;

$chart-carosal-thumb: #cbd1db;

// Typography
$page-heading-description: $base-color;
$default-text-color: $base-color;
$default-text-font-size: 14px;

// UI Elements
$default-border-radius: 15px;
$default-image-background-color: #ededed;

// Icons
$default-icon-color: $base-color;

// Icons
$default-icon-color: $base-color;

@keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@use "../themes/themes.scss" as current-theme;
@use "../helpers/" as helpers;

:root {
  --default-html-font-size: 14;

  // --base-font-size: 14px;

  // Font weights
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-heavy: 900;
}

$default-html-font-size: 14;

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-display: swap;
  src: url("../../fonts/Inter-Regular.woff2") format("woff2"),
    url("../../fonts/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: "Inter-medium";
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-display: swap;
  src: url("../../fonts/Inter-Medium.woff2") format("woff2"),
    url("../../fonts/Inter-Medium.woff") format("woff");
}

@font-face {
  font-family: "Inter-semi-bold";
  font-style: normal;
  font-weight: var(--font-weight-semi-bold);
  font-display: swap;
  src: url("../../fonts/Inter-SemiBold.woff2") format("woff2"),
    url("../../fonts/Inter-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Inter-bold";
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-display: swap;
  src: url("../../fonts/Inter-Bold.woff2") format("woff2"),
    url("../../fonts/Inter-Bold.woff") format("woff");
}

.dashboard, .dashboard * {
  font-family: Inter;
  // font-size: helpers.pxToRem(14);
  box-sizing: border-box;
  color: var(--primary-theme-color);
  line-height: helpers.pxToRem(20);
}

@import '../scss/variables.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba($c-primary-color-hover, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  text-align: left;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}


//
// Typography
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
p,
.p {
  margin: 0;
  padding: 0;
}

// Abbreviations
//

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  margin: 0;
  padding: 0;
  text-decoration-skip-ink: none;
}

address {
  margin: 0;
  padding: 0;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin: 0;
  padding: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin: 0;
  padding: 0;
}

dt {
  margin: 0;
  padding: 0;
}

dd {
  margin: 0;
  padding: 0;
}

blockquote {
  margin: 0;
  padding: 0;
}

b,
strong {}

small {}

sub,
sup {
  position: relative;
  line-height: 0;
  vertical-align: baseline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;

  &::after {
    color: inherit;
    text-decoration: none;
  }
}

//
// Code
//

pre,
code,
kbd,
samp {}

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}


//
// Figures
//

figure {
  margin: 0;
}


//
// Images and content
//

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

//
// Tables
//

table {
  border-collapse: collapse;
}

caption {
  padding: 0;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

//
// Forms
//

label {
  display: inline-block;
  margin: 0;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

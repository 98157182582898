@mixin pxToRem($property, $value) {
  #{$property}: ($value / 16) + rem;
}

@mixin common-icon-styles($icon-source) {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px;
    background-image: url("#{$icon-source}") !important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@mixin common-mask-bg-image-styles(
  $size: contain,
  $repeat: no-repeat,
  $position: center,
  $bg-color: transparent
) {
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: $bg-color;
}

// @mixin common-bg-image-styles(
//   $size: contain,
//   $repeat: no-repeat,
//   $position: center
// ) {
//   background-size: $size;
//   background-repeat: $repeat;
//   background-position: $position;
// }

@mixin common-bg-image-styles($size: contain, $position: center) {
  background-size: $size;
  background-repeat: no-repeat;
  background-position: $position;
}

@mixin injectBackgroundImage($bgImageSource) {
  background-image: url(#{$bgImageSource});
  @include common-bg-image-styles();
}

@mixin displayFlex(
  $display: flex,
  $flexDirection: column,
  $justifyContent: flex-start,
  $alignItems: center
) {
  display: $display;
  flex-direction: $flexDirection;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

.modal-tabs {
  display: flex;
  flex-direction: row;
  gap: 5px;

  .tab-button {
    color: var(--primary-theme-color);
    background-color: #fff;
    padding: 13px;
    border-radius: 8px 8px 0 0;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    border: 3px #f0f0f5 solid;

    &.active {
      background-color: #f0f0f5;
    }
  }
}

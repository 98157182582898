@use "common" as common;
@use "../helpers/" as helpers;

.toggle-button {
  cursor: common.$component-clickable-control-cursor;
  display: inline-block;

  &__switch {
    display: inline-flex;
    color: var(--primary-theme-color);
    background: #ffffff;
    border: common.$component-control-border;
    border-radius: common.$component-control-border-radius;
    width: auto;
    min-height: common.$component-control-min-height;
    position: relative;
    justify-content: center;
    transition: background 0.25s;
    align-items: center;
    padding: common.$component-control-padding;
    // @include helpers.injectBackgroundImage(var(--icon-filters));

    &:hover {
      background-color: var(--component-control-hovered-bg-color);

      > * {
        color: #ffffff;
      }

      > .icon-filters--outline {
        background-color: #ffffff !important;
      }
    }

    &:active {
      background-color: var(--clicked-item-bg-color);
      border-color: var(--clicked-item-bg-color);
    }

    .toggle-button__checkbox:checked + & {
      color: #ffffff;
      background: var(--active-item-color);

      &:before {
        left: 30px;
      }

      > .icon-filters--outline {
        background-color: #ffffff !important;
      }
    }

    &__icon {
      display: inline-block;
      width: 1em;
      height: 1em;
    }

    &__label {
      margin-right: 1em;
    }
  }

  .toggle-button__checkbox {
    position: absolute;
    visibility: hidden;
  }
}

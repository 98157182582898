@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin animation-fadeIn() {
  animation: fadeIn 300ms ease-in both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loading-on {
  background-position: calc(100% - 10px);
}

@keyframes slideInFromLeft {
  from {
    left: -100%;
    opacity: 0.2;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

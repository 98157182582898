@use "../variables.scss" as vars;

:root {
  // Branding
  --primary-theme-color: #{vars.$base-color};
  --secondary-theme-color: #{vars.$c-primary-color};

  --app-bg-color: #{vars.$app-bg-color};

  --app-red-color: #{vars.$c-error-color};
  --app-yellow-color: hsla(46, 100%, 90%, 1);
  // --app-green-color: hsl(160, 79%, 44%);
  --app-green-color: #{vars.$c-primary-color};
  --app-gray-color: hsla(160, 12%, 5%, 0.169);

  --app-red-text-color: hsla(0, 100%, 9%, 1);
  --app-yellow-text-color: hsla(46, 100%, 9%, 1);
  // --app-green-text-color: hsla(151, 100%, 9%, 1);
  --app-green-text-color: #{vars.$assets};
  --app-gray-text-color: hsla(151, 11%, 2%, 0.03);

  // Layouts
  --layout-bg-color: #f0f1f4;
  --layout-disabled-bg-color: #c4c4c4;
  --layout-overlay-bg-color: #2a436550;
  --layout-shadow: 0px 3px 10px #0000000d;
  --layout-shadow-highlight: rgba(0, 0, 0, 0.1) 0px 0 32px 0px;
  --inner-separator-border-color: #{vars.$c-border-color-1};
  --outer-separator-border-color: #{vars.$input-border-color};
  --overlay-color: hsla(
    var(--primary-hue),
    var(--primary-saturation),
    var(--primary-lightness),
    0.5
  );

  // UI Component States
  --common-checkbox-border-color: #{vars.$c-sub-text-color};
  --common-hovered-bg-color: hsl(
    var(--primary-hue),
    var(--primary-saturation),
    96%
  );
  --card-hovered-bg-color: hsl(
    var(--primary-hue),
    var(--primary-saturation),
    35%
  );
  --component-control-hovered-bg-color: hsl(
    var(--secondary-hue),
    var(--secondary-saturation),
    40%
  );

  // Links
  --link-secondary-color: hsl(
    var(--secondary-hue),
    var(--secondary-saturation),
    40%
  );

  --app-success-color: var(--app-green-color);
  --app-success-bg-color: #18c88c0a;
  --app-warning-color: var(--app-yellow-color);
  --app-critical-color: var(--app-red-color);
  --active-item-color: var(--secondary-theme-color);
  --active-sidebar-menu-item: hsl(
    var(--primary-hue),
    var(--primary-saturation),
    96%
  );
  --inactive-item-color: #686868;
  --disabled-item-color: #c4c4c4;
  --focused-item-color: hsl(
    var(--secondary-hue),
    var(--secondary-saturation),
    35%
  );
  --focused-item--inner-shadow: inset 0 0 4px #c4c4c4ea;
  --clicked-item-bg-color: hsl(160, 79%, 30%);

  //Typography
  --sub-heading-color: #{vars.$c-sub-text-color};
  --sub-heading-2-color: #6a7b93;
  --placeholder-text-color: hsl(
    var(--primary-hue),
    var(--primary-saturation),
    45%
  );
  --dashboard-card-sub-text-color: #{vars.$c-sub-text-color};
  --sub-text-color: #e0e0e0;
}

.dark-theme {
  --primary-theme-color: #e0e0e0;
  --secondary-theme-color: #18c88c;

  --app-bg-color: #4d6584;

  // Layouts
  --layout-bg-color: #26313f;

  .panel-center {
    background-color: var(--app-bg-color);
  }

  .dashboard {
    .dashboard-card {
      background-color: var(--layout-bg-color);
    }
  }
}

.green-theme {
  --primary-theme-color: #ffffff;
  --secondary-theme-color: #18c88c;

  --app-bg-color: #073e2c;

  // Layouts
  --layout-bg-color: #05271c;

  .panel-center {
    background-color: var(--app-bg-color);
  }

  .dashboard {
    .dashboard-card {
      background-color: var(--layout-bg-color);
    }
  }
}

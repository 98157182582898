/* Dropdown Wrapper */
.checkboxes-dropdown {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
}

/* Hidden Checkbox for Toggle */
.checkboxes-dropdown__checkbox {
  display: none;
}

/* Overlay to Detect Outside Clicks */
.checkboxes-dropdown__overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Show the Overlay when the Dropdown is Open */
.checkboxes-dropdown__checkbox:checked ~ .checkboxes-dropdown__overlay {
  display: block;
}

/* Dropdown Container */
.checkboxes-dropdown__container {
  position: relative;
  z-index: 2;
}

/* Dropdown Toggle Button */
.checkboxes-dropdown__toggle {
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  color: var(--primary-theme-color);
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  background-size: 20px;
  background-position: 16px center;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* Dropdown Menu (Initially Hidden) */
.checkboxes-dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

/* Show Dropdown Menu when the Checkbox is Checked */
.checkboxes-dropdown__checkbox:checked
  ~ .checkboxes-dropdown__container
  .checkboxes-dropdown__menu {
  display: block;
}

/* Menu Items */
.checkbox {
  padding: 0.25rem 1.5rem;
  width: 100%;

  &__label {
    font-size: 16px;
  }
}

/* Checkbox Styling */
.checkboxes-dropdown__menu-item input[type="checkbox"] {
  margin-right: 10px;
}

@use "../helpers/" as helpers;

.checkbox {
  cursor: pointer;
  display: inline-flex;

  &__box {
    display: none;
  }

  &__label {
    display: inline-flex;
    font-size: 13px;
  }

  &__box + &__label &__tick {
    display: inline-block;
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    font-size: 13px;
    margin: 0 5px 0 0;
    vertical-align: middle;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    // background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/check_radio_sheet.png)
    //   left top no-repeat;
    cursor: pointer;
    // background-size: 67%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease;
  }

  &__box:checked + &__label &__tick {
    @include helpers.icon-solid-bg(icon-tick);
    @include helpers.common-bg-image-styles(67%);
    background-color: var(--secondary-theme-color);
    border-color: var(--secondary-theme-color);
    transition: all 0.3s ease;
    // background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/check_radio_sheet.png) -19px
    //   top no-repeat;
  }
}

$panel-border-radius: 16px;
$panel-box-shadow: rgba(42, 67, 101, 0.05) 0px 2px 6px 3px;
$component-clickable-control-cursor: pointer;
$component-control-background-color: red;
$component-control-border-radius: 8px;
$component-control-min-height: 40px;
$component-control-border: 1px solid var(--primary-theme-color);
$component-control-color: green;
$component-control-focus-outline: 2px;
$component-control-margin: 0;
$component-control-padding: 0.3em 0.8em;
$component-placeholder-color: lightbrown;
$link-visited-color: pink;

.--no {
  &-margin {
    &-top {
      margin-top: 0 !important;
    }

    &-right {
      margin-right: 0 !important;
    }

    &-bottom {
      margin-bottom: 0 !important;
    }

    &-left {
      margin-left: 0 !important;
    }
  }

  &-padding {
    &-top {
      padding-top: 0 !important;
    }

    &-right {
      padding-right: 0 !important;
    }

    &-left {
      padding-bottom: 0 !important;
    }

    &-bottom {
      padding-left: 0 !important;
    }
  }
}

@use "../../scss/variables.scss" as vars;

body {
  background-color: var(--app-bg-color);
}

.separator {
  width: 100%;
  margin: 1em 0;
  height: 0.7px;
  background-color: var(--inner-separator-border-color);

  &--vertical {
    width: 0.75px;
    height: 100%;
    margin-inline: 1em;
    background-color: #{vars.$c-border-color-1};
  }
}

@mixin default-scroll-style {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #dbdcdd;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f3f5;
  }
}

@mixin disabled-ui-overlay-common-styles {
  display: flex;
  width: 100%;
  height: 84%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 16px 16px;

  color: var(--app-red-color);
  font-size: 1rem;
  background: rgb(237, 237, 237);
  background: linear-gradient(
    180deg,
    rgba(237, 237, 237, 0.7960317916228992) 60%,
    rgba(237, 237, 237, 1) 100%
  );
}

.disabled-ui-overlay {
  &-en {
    position: relative;
    &::after {
      @include disabled-ui-overlay-common-styles();
      content: "No data available.";
    }
  }

  &-no {
    position: relative;
    &::after {
      @include disabled-ui-overlay-common-styles();
      content: "Ingen data tilgjengelig.";
    }
  }

  &.card-revenue {
    &::after {
      height: 72%;
    }
  }

  &.card-ratio {
    &::after {
      height: 49%;
      top: 0;
      border-radius: 16px 16px 0 0;
    }
  }
}

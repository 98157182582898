.--width-100 {
  width: 100%;
}

.--width-50 {
  width: 50%;
}

.--stack-column {
  display: flex;
  flex-direction: column;
}

:root {
  --responsive-xs: 15.625em;
  --responsive-sm: 43.75em;
  --responsive-md: 90em;
  --responsive-lg: 120em;
  --responsive-xl: 120.0625em;
  --isMobileMode: false;
}

$breakpoints: (
  "xs": 15.625em,
  "sm": 43.75em,
  "md": 90em,
  "lg": 120em,
  "xl": 120.0625em,
);

@mixin xs($orientation: landscape) {
  @media screen and (max-width: map-get($breakpoints, "xs")) and (orientation: $orientation) {
    @content;
  }
}

@mixin sm() {
  @media screen and (max-width: map-get($breakpoints, "sm")) {
    @content;
  }
}

@mixin sm($orientation: landscape) {
  @media screen and (max-width: map-get($breakpoints, "sm")) and (orientation: $orientation) {
    @content;
  }
}

@mixin md($orientation: landscape) {
  @media screen and (max-width: map-get($breakpoints, "md")) and (orientation: $orientation) {
    @content;
    .app-left {
      display: none;
    }
  }
}

@mixin lg($orientation: landscape) {
  @media screen and (max-width: map-get($breakpoints, "lg")) and (orientation: $orientation) {
    @content;
  }
}

@mixin xl($orientation: landscape) {
  @media screen and (min-width: map-get($breakpoints, "xl")) and (orientation: $orientation) {
    @content;
  }
}

@mixin breakpointMin($breakpoint: 0px, $orientation: landscape) {
  @media screen and (min-width: $breakpoint) and (orientation: $orientation) {
    @content;
  }
}

@mixin breakpointMax($breakpoint: 1920px, $orientation: landscape) {
  @media screen and (max-width: $breakpoint) and (orientation: $orientation) {
    @content;
  }
}

@mixin breakpointMinOnBothOrientations($breakpoint: 1920px) {
  @media screen and (min-width: $breakpoint) and (orientation: "landscape") {
    @content;
  }

  @media screen and (min-width: $breakpoint) and (orientation: "portrait") {
    @content;
  }
}

@mixin breakpointMaxOnBothOrientations($breakpoint: 1920px) {
  @media screen and (max-width: $breakpoint) and (orientation: "landscape") {
    @content;
  }

  @media screen and (max-width: $breakpoint) and (orientation: "portrait") {
    @content;
  }
}
